.google-place [class$='control'] {
    border-radius: 2px;
    min-height: 0;
    height: 34px;
}
.google-place [class$='control'] > div {
    padding-top: 1px;
    padding-bottom: 1px;
}

.google-place [class$='indicatorContainer'] {
    padding: 4px;
}

.google-place [class$='control']:focus-within,
.google-place [class$='control']:hover {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
} 
